import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import TopSection from "../components/common/topSection"
import CardsSection, { CardType } from "../components/common/cards/cardsSection"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wave, { WaveType } from "../components/ui/wave"
import { PageType } from "./about"
import { BlogPostType } from "../components/blog/blogPostSection"
import { Locale } from "../common/appContext"
import Steps from "../components/common/Steps"
import Body from "../components/employer/Body"

const employer = ({ path }) => {
  const {
    graphCmsPage,
    allGraphCmsCard,
    allGraphCmsBlogPost,
  } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckqnhgjjk2j8y0b12qjs3ik50:sv:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }
      allGraphCmsCard(
        filter: {
          cardCategory: { category: { eq: "employer" } }
          locale: { eq: sv }
        }
      ) {
        nodes {
          body
          heading
        }
      }
      allGraphCmsBlogPost(
        sort: { order: ASC, fields: createdAt }
        filter: {
          stage: { eq: PUBLISHED }
          blogCategories: {
            elemMatch: { category: { eq: "howItWorks_app_employer" } }
          }
          locale: { eq: sv }
        }
      ) {
        nodes {
          blogCategories {
            category
            color
            slug
          }
          body {
            html
          }
          heading
          summary
          slug
          picture {
            alt
            id
            url
            localFile {
              childrenImageSharp {
                gatsbyImageData(width: 596, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)

  const data: PageType = graphCmsPage
  const cards: CardType[] = allGraphCmsCard.nodes
  let posts: BlogPostType[] = allGraphCmsBlogPost.nodes

  return (
    <Layout locale={Locale.sv} path={path}>
      <SEO title="employer" meta={data.seo} />

      <div
        data-sal={"slide-right"}
        data-sal-delay="200"
        data-sal-easing="ease"
        className="container container-x-small d-flex py-10 justify-content-center"
      >
        <article>
          <h2>{data.heading}</h2>
        </article>
      </div>

      <section className="container">
        <Steps posts={posts} />
      </section>

      <section>
        <Wave pathClassName={"u-c-orange"} type={WaveType.v4} />
        <div className="u-bg-orange">
          <div className="container py-5">
            <CardsSection
              headingClassName="u-c-white"
              cards={cards}
              className="neu-morphism-orange u-c-white"
            />
          </div>
          <Wave className={"u-c-white"} type={WaveType.v1} />
        </div>
      </section>

      <section>
        <section>

          <Body body={data.body} />
        </section>
      </section>

      <Wave pathClassName={"u-c-orange"} type={WaveType.v2} />
    </Layout>
  )
}

export default employer
